import React from 'react'
import { Breadcrumb } from '../components/breadcrumb'
import Layout from '../components/layout'
import Seo from '../components/seo'

const AboutUs = () => {
  const infoSection = (
    <div className="container">
      <div className="row animated fadeInUp delay1">
        <div className="col-md-5">
          <h1>Über uns</h1>
        </div>
        <div className="col-md-7">
          <p>Moinsen {'{Hamburg}'}</p>
        </div>
      </div>
    </div>
  )

  return (
    <Layout infoSection={infoSection}>
      <Seo title="Über uns" />
      <Breadcrumb crumbs={[{label: 'Über uns'}]} />
      <div className="row-fluid info_title">
        <div className="vertical_line">
          <div className="circle_bottom"></div>
        </div>
        <div className="info_vertical">
          <h1>
            Über <span>Uns</span>
          </h1>
        </div>
      </div>

      <section className="content_info">
        <div className="paddings">
          <div className="container wow fadeInUp">
            <div className="row">
              <div className="col-md-4">
                <h4>Unsere Geschichte</h4>
                <p>
                  Durch unsere Arbeit als Freelancer haben wir mit vielen
                  Produkten zahlreicher Agenturen arbeiten müssen, die sowohl
                  fachlich als auch technisch viel zu Wünschen übrig ließen.
                  <br /> Wir wissen wir können das Besser! Daher haben wir uns
                  entschlossen "Moinsen" zu gründen. Wir wollen Software,
                  vorallem Apps und Web&shy;anwendungen bauen, die von uns
                  einfach richtig gemacht wird. Softwareentwicklung ist auch so
                  schon teuer genug, da sollte man sich als Kunde nicht mit
                  schlechter Softwareentwicklung zu frieden geben. Schlechte
                  Software wird es bei uns nicht geben!
                </p>
              </div>
              <div className="col-md-4">
                <h4>Unser Leitsatz</h4>
                <p>
                  Kern einer guten Software ist immer ein solides MVP, das den
                  Namen verdient. Ein gutes MVP muss nicht in der nächsten
                  Iteration neugebaut werden, stattdessen wird es konstant
                  weiter entwickelt.
                  <br /> An dieser Stelle kommen uns unsere reichen Erfahrungen
                  zu Gute, die es uns ermöglichen auch komplexe Software in
                  kleinen Schritten auf den Markt zubringen ohne dabei die
                  Produktvision aus den Augen zu verlieren.
                </p>
              </div>
              <div className="col-md-4">
                <h4>Unsere Methode</h4>
                <p>
                  „Code is not readonly“. Eine wichtige Erkenntnis, die jedoch
                  oftmals falsch genutzt wird und dazu führt, dass schlechte
                  MVPs durch neue Features eine qualitative Talfahrt zu einen
                  schlechten Endprodukt fortsetzen.
                  <br />
                  Wir verstehen es ganz anders: Es ermöglicht uns, schnell auf
                  veränderte Anforderungen oder Prioritäten unsere Kunden zu
                  reagieren. Wir probieren gerne neue erfolgsversprechende
                  Ansäzte aus und arbeiten gemeinsam mit dem Kunden daran, die
                  Ergebnisse aus solchen Experimenten einzuordnen.
                  <br />
                  Wichtig ist immer, dass wir unsere gemeinsamen Ziele vor Augen
                  haben und stetig der Vision entgegen arbeiten. Dazu gehört
                  neben dem offensichtlich Sichtbaren, auch die
                  Software-Qualität - von der kleinsten Funktion bis zur
                  Applikations-Architektur.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content_info">
        <div className=""></div>

        <section className="opacy_bg_02 paddings">
          <div className="container wow fadeInUp">
            <div className="row results results-no-top">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <i className="fa fa-bomb"></i>
                <h2>
                  69.760 <span>+</span>
                </h2>
                <h5>STUNDEN GEARBEITET SEIT 1992</h5>
              </div>
              <div className="col-md-2">
                <i className="fa fa-group"></i>
                <h2>
                  61 <span>+</span>
                </h2>
                <h5>KUNDEN</h5>
              </div>
              <div className="col-md-2">
                <i className="fa fa-coffee"></i>
                <h2>
                  112 <span>=</span>
                </h2>
                <h5>PROJEKTE ABGESCHLOSSEN</h5>
              </div>
              <div className="col-md-2">
                <i className="fa fa-briefcase"></i>
                <h2>40</h2>
                <h5>JAHRE ERFAHRUNG</h5>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="row-fluid info_title">
        <div className="vertical_line">
          <div className="circle_bottom"></div>
        </div>
        <div className="info_vertical">
          <h2>
            Mit der <span>Vision</span> im Kopf zum MVP
          </h2>
          <p>
            „Damit das Konzept eines MVP aufgeht und erfolgreich ist, muss die
            Vision des Produktes immer Teil des Entwicklungsprozesses sein.“
          </p>
        </div>
        <div className="vertical_line"></div>

        <i className="fa fa-bullseye left"></i>
      </div>

      <div className="info_resalt borders">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="item-team">
                <img src="/img/team/uli.webp" alt="" />
                <h4>
                  Ulrich Diedrichsen
                  <span>Architektur, Development und Entrepreneur</span>
                </h4>
                <p>
                  Unser erfahrenster Mann. Seit den 90ziger Jahren als
                  Softwareentwickler und Unternehmensberater ist auf vier
                  Kontinenten unterwegs. Mit erfolgreichen Einsätzen bei über 50
                  Kunden kann Uli auf einen tatsächlichen Erfahrungsschatz
                  aufbauen, der jedem folgenden Projekt zu Gute kommt, sowohl
                  bei der langfristigen Ausrichtung von Projekten als auch der
                  technischen Entwicklung.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <section className="content_info">
        <div className="paddings">
          <div className="container wow fadeInUp">
            <div className="row">
              <div className="col-md-6">
                <h2>Unser Team</h2>
                <p>
                  Mit Uli haben wir einen langjährigen Unternehmensberater an
                  der Seite, der unsere Kunden auch über die eigentliche
                  App-Entwicklung hinaus beraten kann. Auch wenn dies nicht
                  unsere Kerngeschäft sein wird, soll es doch jedem unserer
                  Kunden helfen, die gewünschte Software korrekt und zielgenau
                  auszurichten. Nur mit einem Blick auf Unternehmensziele und
                  -ausrichtung kann genau die Maßgeschneiderte Software
                  geliefert werden, die zum ihm passt.
                </p>
              </div>

              <div className="col-md-6">
                <div className="skills-wrapper wow animated fadeInRight">
                  <h6 className="heading-progress">
                    Web Development <span className="pull-right">100%</span>
                  </h6>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{width: '100%'}}
                      aria-valuemax="100"
                      aria-valuemin="0"
                      aria-valuenow="95"
                      role="progressbar"
                    ></div>
                  </div>
                  <h6 className="heading-progress">
                    App Development <span className="pull-right">100%</span>
                  </h6>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{width: '100%'}}
                      aria-valuemax="100"
                      aria-valuemin="0"
                      aria-valuenow="95"
                      role="progressbar"
                    ></div>
                  </div>
                  <h6 className="heading-progress">
                    Architektur <span className="pull-right">80%</span>
                  </h6>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{width: '80%'}}
                      aria-valuemax="100"
                      aria-valuemin="0"
                      aria-valuenow="80"
                      role="progressbar"
                    ></div>
                  </div>
                  <h6 className="heading-progress">
                    Business Development <span className="pull-right">70%</span>
                  </h6>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{width: '70%'}}
                      aria-valuemax="100"
                      aria-valuemin="0"
                      aria-valuenow="70"
                      role="progressbar"
                    ></div>
                  </div>
                  <h6 className="heading-progress">
                    UI / UX <span className="pull-right">95%</span>
                  </h6>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{width: '95%'}}
                      aria-valuemax="100"
                      aria-valuemin="0"
                      aria-valuenow="95"
                      role="progressbar"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="row-fluid info_title">
        <div className="vertical_line">
          <div className="circle_bottom"></div>
        </div>
        <div className="info_vertical" id="scale-it">
          <h2>Unsere Kunden</h2>
          <p>
            Hier eine kleine Auswahl unserer Kunden, für die wir in der
            Vergangenheit u.a. als Freelancer tätig waren.
          </p>
        </div>
        <div className="vertical_line"></div>

        <i className="fa fa-briefcase right"></i>
      </div>

      <section className="content_info">
        <div className="info_resalt">
          <div className="container wow fadeInUp">
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="owl-carousel carousel-clients tooltip-hover"
                  id="carousel-clients"
                >
                  <li data-toggle="tooltip" data-original-title="Volkswagen">
                    <div className="tooltip_hover" title="Volkswagen">
                      <img src="/img/logos/vw.webp" alt="Image" />
                    </div>
                  </li>
                  <li data-toggle="tooltip" data-original-title="About You">
                    <div className="tooltip_hover" title="About You">
                      <img src="/img/logos/about-you.webp" alt="Image" />
                    </div>
                  </li>
                  <li data-toggle="tooltip" data-original-title="Commerzbank">
                    <div className="tooltip_hover" title="Commerzbank">
                      <img src="/img/logos/commerzbank.webp" alt="Image" />
                    </div>
                  </li>
                  <li data-toggle="tooltip" data-original-title="Finanzcheck">
                    <div className="tooltip_hover" title="Finanzcheck">
                      <img src="/img/logos/finanzcheck.webp" alt="Image" />
                    </div>
                  </li>
                  <li data-toggle="tooltip" data-original-title="ibm">
                    <div className="tooltip_hover" title="ibm">
                      <img src="/img/logos/ibm.webp" alt="Image" />
                    </div>
                  </li>
                  <li data-toggle="tooltip" data-original-title="IT Ergo">
                    <div className="tooltip_hover" title="IT Ergo">
                      <img src="/img/logos/itergo.webp" alt="Image" />
                    </div>
                  </li>
                  <li
                    data-toggle="tooltip"
                    data-original-title="McKinsey & Company"
                  >
                    <div className="tooltip_hover" title="McKinsey & Company">
                      <img src="/img/logos/mckinsey.webp" alt="Image" />
                    </div>
                  </li>
                  <li data-toggle="tooltip" data-original-title="pwc">
                    <div className="tooltip_hover" title="pwc">
                      <img src="/img/logos/pwc.webp" alt="Image" />
                    </div>
                  </li>
                  <li
                    data-toggle="tooltip"
                    data-original-title="SinnerSchrader"
                  >
                    <div className="tooltip_hover" title="SinnerSchrader">
                      <img src="/img/logos/s2.webp" alt="Image" />
                    </div>
                  </li>
                  <li data-toggle="tooltip" data-original-title="Schidtgruppe">
                    <div className="tooltip_hover" title="Schidtgruppe">
                      <img src="/img/logos/schmidtgruppe.webp" alt="Image" />
                    </div>
                  </li>
                  <li data-toggle="tooltip" data-original-title="Tchibo">
                    <div className="tooltip_hover" title="Tchibo">
                      <img src="/img/logos/tchibo.webp" alt="Image" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutUs
